import '../PlicCanvas/vue/utils/polyfills';

require('../PlicCanvas/js/layout-preview.js');
require('../PlicCanvas/css/layout-preview.css');
require('../PlicCanvas/js/flow-layout/svg-fonts.js');
require('../PlicCanvas/js/flow-layout/dynamic-variable-globals.js');
require('../PlicCanvas/js/flow-layout/frame-utils.js');
require('../PlicCanvas/vue/common/common-libs');

import vuetify from '../js/plugins/vuetify';
import RebrandBanner from '../PlicCanvas/vue/common/RebrandBanner.vue';
import { REBRAND_BANNER_MESSAGE } from '../vue/constants';


Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: h => h(RebrandBanner, { props: { message: REBRAND_BANNER_MESSAGE } })
}).$mount('#rebrand-banner');